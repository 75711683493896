import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RaffleService } from '../../../shared/services/raffle.service';
import { QuotaService } from '../../../shared/services/quota.service';
import { MercadoPagoService } from '../../../shared/services/mercado-pago.service'; // Serviço de Mercado Pago
import { PaymentService } from '../../../shared/services/payment.service'; // Serviço de pagamento
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { Quota } from '../../../shared/models/quota.model'; // Modelo de Quota
import { Payment } from '../../../shared/models/payment.model'; // Modelo de Pagamento

@Component({
  selector: 'app-auction-live',
  templateUrl: './auction-live.component.html',
  styleUrls: ['./auction-live.component.scss']
})
export class AuctionLiveComponent implements OnInit {

  raffleData: any = {};
  slug!: string;
  auctiontime!: string;
  private _diff!: number;

  // Declaração da propriedade breadCrumbItems
  breadCrumbItems: { label: string, link?: string, active?: boolean }[] = [];

  // Contador
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;

  // Propriedades para bid
  contact: string = '';
  quantity: number = 1;
  isValidContact: boolean = true; // Para validar contato

  constructor(
    private route: ActivatedRoute,
    private raffleService: RaffleService,
    private quotaService: QuotaService,
    private mercadoPagoService: MercadoPagoService,
    private paymentService: PaymentService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get('slug')!;

    console.log('Slug:', this.slug);

    // Carrega os dados da rifa
    this.raffleService.getRaffleBySlug(this.slug).subscribe((data: any) => {
      console.log('Raffle data:', data);
      this.raffleData = data;
      this.auctiontime = data.draw_date;
      this.startCountdown();
    }, (error) => {
      console.error('Error fetching raffle data:', error);
    });

    // Inicializa os itens de breadcrumb
    this.breadCrumbItems = [
      { label: 'Home', link: '/' },
      { label: 'Marketplace', link: '/' },
      { label: 'Auction Live', active: true, link: `/auctionlive/${this.slug}` }
    ];
  }

  // Função para inicializar o contador
  startCountdown() {
    interval(1000).pipe(map(() => {
      const currentTime = new Date().getTime();
      const auctionEndTime = new Date(this.auctiontime).getTime();
      this._diff = auctionEndTime - currentTime;

      this.days = Math.floor(this._diff / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((this._diff / (1000 * 60 * 60)) % 24);
      this.minutes = Math.floor((this._diff / (1000 * 60)) % 60);
      this.seconds = Math.floor((this._diff / 1000) % 60);
    })).subscribe();
  }

  // Função chamada quando o campo de contato é atualizado
  onContactInput() {
    const contactValue = this.contact;

    // Verifica se é um e-mail válido
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmail = emailPattern.test(contactValue);

    // Verifica se é um número de telefone válido
    const phonePattern = /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/;
    const isPhone = phonePattern.test(this.formatPhone(contactValue));

    // Aplica a máscara de telefone automaticamente se for um número de telefone
    if (isPhone) {
      this.contact = this.formatPhone(contactValue);
    }

    // Verifica se o valor é um telefone ou e-mail válido
    this.isValidContact = isEmail || isPhone;
  }

  // Função para formatar o telefone
  formatPhone(phone: string): string {
    phone = phone.replace(/\D/g, ''); // Remove tudo que não é número

    if (phone.length === 11) {
      // Formato para (XX) XXXXX-XXXX
      return `(${phone.substring(0, 2)}) ${phone.substring(2, 7)}-${phone.substring(7)}`;
    } else if (phone.length === 10) {
      // Formato para (XX) XXXX-XXXX
      return `(${phone.substring(0, 2)}) ${phone.substring(2, 6)}-${phone.substring(6)}`;
    }
    return phone;
  }

  // Função para realizar o bid
  placeBid() {
    if (!this.isValidContact) {
      alert('Por favor, insira um contato válido antes de prosseguir.');
      return;
    }

    const bidData: Omit<Quota, '_id'> = {
      raffle_id: this.raffleData._id || '',
      contact: this.cleanContact(this.contact),
      quantity: this.quantity,
      status: 'pending'
    };

    console.log('Bid data:', bidData);

    this.quotaService.createQuota(bidData).subscribe(
      (response: Quota) => {
        console.log('Bid placed successfully:', response);

        if (response._id) {
          this.createSystemPayment(response._id, response.quantity * this.raffleData.quota_value);
        } else {
          console.error('Quota ID is missing in the response');
        }
      },
      (error) => {
        console.error('Error placing bid:', error);
      }
    );
  }

  // Função para criar o pagamento no sistema
  createSystemPayment(quotaId: string, amount: number) {
    // Primeiro, cria o pagamento via PIX no Mercado Pago para obter o ID do pagamento
    this.mercadoPagoService.createPixPayment({
      transaction_amount: amount,
      description: `Pagamento da cota para a rifa ${this.raffleData.name}`,
      payer: { email: 'guilherme@rede5.com.br' }
    }).subscribe(
      (pixResponse) => {
        console.log('PIX Payment response:', pixResponse);

        // Verifica se o pagamento foi criado e obtém o ID do gateway
        if (pixResponse.data && pixResponse.data.id) {
          const paymentData: Payment = {
            quota_id: quotaId,
            amount: amount,
            payment_method: 'mercado_pago_pix',
            payment_type: 'quota',
            payment_status: 'pending',
            payment_gateway_id: pixResponse.data.id // Atribui o ID do Mercado Pago
          };

          // Agora cria o pagamento no sistema com o ID do gateway incluído
          this.paymentService.createPayment(paymentData).subscribe(
            (paymentResponse: Payment) => {
              console.log('System Payment created successfully with gateway ID:', paymentResponse);

              // Redireciona após criar o pagamento no sistema
              this.router.navigate(['/buy', paymentResponse.id]);
            },
            (error) => {
              console.error('Error creating system payment:', error);
            }
          );
        } else {
          console.error('Payment ID is missing in the PIX response');
        }
      },
      (error) => {
        console.error('Erro ao criar pagamento via PIX:', error);
      }
    );
  }

  // Função para limpar contato (remover caracteres não numéricos de telefones)
  cleanContact(contact: string): string {
    return contact.replace(/\D/g, ''); // Remove tudo que não é número
  }
}