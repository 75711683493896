import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../../shared/services/payment.service';
import { MercadoPagoService } from '../../../shared/services/mercado-pago.service';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-auction-buy',
  templateUrl: './auction-buy.component.html',
  styleUrls: ['./auction-buy.component.scss']
})
export class AuctionBuyComponent implements OnInit, OnDestroy {
  qrCodeUrl: string = '';
  pixCode: string = '';
  amount: number = 0;
  isLoading: boolean = true;
  errorMessage: string = '';
  paymentGatewayId: string | null = null;
  paymentApproved: boolean = false; // Variável para controlar a exibição do status aprovado
  private statusSubscription: Subscription | undefined;
  private retryCount: number = 0;

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private mercadoPagoService: MercadoPagoService
  ) { }

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;

    const paymentId = this.route.snapshot.paramMap.get('id');
    console.log('ID do pagamento obtido da URL:', paymentId);

    if (paymentId) {
      this.getPaymentDetails(paymentId);
    } else {
      this.isLoading = false;
      this.errorMessage = 'ID do pagamento não encontrado na URL.';
    }
  }

  // Função para buscar os detalhes do pagamento
  getPaymentDetails(paymentId: string): void {
    console.log('Buscando detalhes do pagamento para o ID:', paymentId);

    this.paymentService.getPaymentById(paymentId).subscribe(
      (payment) => {
        console.log('Detalhes do pagamento recebidos:', payment);

        this.amount = payment.amount;

        if (payment && payment.payment_gateway_id) {
          this.paymentGatewayId = payment.payment_gateway_id;
          this.getMercadoPagoDetails(payment.payment_gateway_id);

          // Inicia a primeira verificação do status
          this.startCheckingPaymentStatus(payment.payment_gateway_id);
        } else {
          this.isLoading = false;
          this.errorMessage = 'payment_gateway_id não encontrado no pagamento.';
          console.error('payment_gateway_id não encontrado no pagamento.');
        }
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage = 'Erro ao obter os detalhes do pagamento.';
        console.error('Erro ao obter os detalhes do pagamento:', error);
      }
    );
  }

  // Função para buscar os detalhes do Mercado Pago usando payment_gateway_id
  getMercadoPagoDetails(paymentGatewayId: string): void {
    console.log('Buscando detalhes do Mercado Pago para o paymentGatewayId:', paymentGatewayId);

    this.mercadoPagoService.getPaymentDetails(paymentGatewayId).subscribe(
      (mercadoPagoData: any) => {
        console.log('Dados completos retornados do Mercado Pago:', mercadoPagoData);

        if (mercadoPagoData && mercadoPagoData.data) {
          const { qr_code_base64, qr_code } = mercadoPagoData.data;

          if (qr_code_base64 && qr_code) {
            this.qrCodeUrl = `data:image/png;base64,${qr_code_base64}`;
            this.pixCode = qr_code;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.errorMessage = 'Dados do QR Code ou código de copia e cola ausentes.';
            console.error('Dados do QR Code ou código de copia e cola ausentes:', mercadoPagoData.data);
          }
        } else {
          this.isLoading = false;
          this.errorMessage = 'Estrutura de dados inesperada retornada pelo Mercado Pago.';
          console.error('Estrutura de dados inesperada retornada pelo Mercado Pago:', mercadoPagoData);
        }
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage = 'Erro ao obter os dados do Mercado Pago.';
        console.error('Erro ao obter os dados do Mercado Pago:', error);
      }
    );
  }

  // Inicia a verificação do status do pagamento com intervalos dinâmicos
  startCheckingPaymentStatus(paymentGatewayId: string): void {
    console.log('Iniciando a verificação do status do pagamento...');
    this.checkPaymentStatus(paymentGatewayId);
  }

  // Função para consultar o status do pagamento e ajustar o intervalo dinamicamente
  checkPaymentStatus(paymentGatewayId: string): void {
    console.log(`Verificando status do pagamento para o paymentGatewayId: ${paymentGatewayId}, tentativa: ${this.retryCount + 1}`);

    this.mercadoPagoService.getPaymentDetails(paymentGatewayId).subscribe(
      (mercadoPagoData: any) => {
        if (mercadoPagoData && mercadoPagoData.data) {
          const { status, id } = mercadoPagoData.data;
          console.log(`Status do pagamento (ID: ${id}): ${status}`);

          if (status === 'approved') {
            this.paymentApproved = true; // Se o status for aprovado, exibimos a mensagem de aprovação
            console.log('Pagamento aprovado!');
            return; // Para de verificar se o pagamento foi aprovado
          }

          if (status === 'pending' && this.retryCount < 7) {
            this.retryCount++;

            let delay = 5000; // Padrão: 5 segundos

            if (this.retryCount === 3) {
              delay = 10000; // 10 segundos para a terceira tentativa
            } else if (this.retryCount === 4 || this.retryCount === 5) {
              delay = 20000; // 20 segundos para a quarta e quinta tentativa
            } else if (this.retryCount === 6) {
              delay = 30000; // 30 segundos para a sexta tentativa
            } else if (this.retryCount >= 7) {
              delay = 36000; // 36 segundos para a sétima em diante
            }

            console.log(`Aguardando ${delay / 1000} segundos para a próxima verificação...`);
            this.statusSubscription = timer(delay).subscribe(() => this.checkPaymentStatus(paymentGatewayId));
          }
        } else {
          console.error('Erro ao obter o status do pagamento:', mercadoPagoData);
        }
      },
      (error) => {
        console.error('Erro ao verificar o status do pagamento:', error);
      }
    );
  }

  // Função para copiar o código PIX para o clipboard
  copyToClipboard(): void {
    if (!this.pixCode) {
      alert('Código PIX indisponível para copiar.');
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = this.pixCode;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert('Código PIX copiado!');
  }

  // Limpa o intervalo ao destruir o componente
  ngOnDestroy(): void {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }
}
