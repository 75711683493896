import { Component, OnInit } from '@angular/core';
import { QuotaService } from '../../../shared/services/quota.service'; // Serviço de Quota
import { Quota } from '../../../shared/models/quota.model'; // Modelo de Quota

@Component({
  selector: 'app-auction-ended',
  templateUrl: './auction-ended.component.html',
  styleUrls: ['./auction-ended.component.scss']
})

// AuctionEnded Component
export class AuctionEndedComponent implements OnInit {

  breadCrumbItems!: any;
  morecollection: any;
  quotas: Quota[] = []; // Array para armazenar as quotas encontradas
  contact: string = ''; // Campo para buscar por contato
  errorMessage: string | null = null;

  constructor(private quotaService: QuotaService) { }

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;

    // Remove header user profile and create button
    document.querySelector('.user')?.classList.add('d-none');
    document.querySelector('.create')?.classList.add('d-none');
    document.querySelector('.craeteitem')?.classList.add('d-none');

    // BreadCrumb
    this.breadCrumbItems = [
      { label: 'Home', link: '/' },
      { label: 'Marketplace', link: '/' },
      { label: 'Single Project', active: true, link: '/Single Project' }
    ];
  }

  // Método para aplicar máscara de telefone (sem plugin) apenas se não for e-mail e tiver 9 ou mais números
  applyMask(): void {
    // Remove tudo que não é número
    const cleaned = this.contact.replace(/\D+/g, '');

    // Aplica a máscara somente se o número tiver 9 ou mais dígitos
    if (cleaned.length >= 9) {
      // Aplica a máscara (xx) xxxxx-xxxx ou (xx) xxxx-xxxx
      if (cleaned.length > 10) {
        this.contact = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7, 11)}`;
      } else if (cleaned.length > 6) {
        this.contact = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6, 10)}`;
      }
    }
  }

  // Método para buscar cotas por contato (e-mail ou telefone)
  searchQuotasByContact(): void {
    if (this.contact) {
      // Validação de email
      const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (emailPattern.test(this.contact)) {
        // Se for um email válido
        this.quotaService.getQuotaByContact(this.contact).subscribe(
          (data) => {
            this.quotas = data;
            this.errorMessage = null;
          },
          (error) => {
            this.errorMessage = 'Nenhuma cota encontrada para esse contato.';
            this.quotas = [];
          }
        );
      } else {
        // Limpa o telefone para enviar sem máscara
        const phone = this.contact.replace(/\D+/g, '');

        // Se for telefone válido (com 10 ou 11 dígitos)
        if (phone.length === 10 || phone.length === 11) {
          this.quotaService.getQuotaByContact(phone).subscribe(
            (data) => {
              this.quotas = data;
              this.errorMessage = null;
            },
            (error) => {
              this.errorMessage = 'Nenhuma cota encontrada para esse contato.';
              this.quotas = [];
            }
          );
        } else {
          this.errorMessage = 'Por favor, insira um email ou telefone válido.';
        }
      }
    }
  }
}
