<!-- Header -->
<app-header></app-header>
<!-- Page Title -->
<div class="page-title-overlap bg-accent pt-4">
    <app-breadcrumbs title="Minhas Cotas"></app-breadcrumbs>
</div>

<section class="container pb-md-4">
    <!-- Formulário de busca por contato -->
    <div class="bg-light shadow-lg rounded-3 px-4 py-lg-4 py-3 mb-5">
        <div class="py-lg-3 py-2 px-lg-3">
            <div class="row gy-4">
                <div class="col-lg-6">
                    <input type="text" class="form-control" placeholder="Insira e-mail ou telefone"
                        [(ngModel)]="contact" (input)="applyMask()">
                </div>
                <div class="col-lg-6">
                    <button class="btn btn-accent" (click)="searchQuotasByContact()">Buscar Cotas</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Exibindo as cotas encontradas -->
    <div class="bg-light shadow-lg rounded-3 px-4 py-lg-4 py-3 mb-5" *ngIf="quotas.length > 0">
        <div class="py-lg-3 py-2 px-lg-3">
            <div *ngFor="let quota of quotas" class="row gy-4">
                <!-- Informações da Cota -->
                <div class="col-lg-6">
                    <div class="position-relative rounded-3 overflow-hidden mb-lg-4 mb-2">
                        <h3 class="h5 mb-2">Cota para o Sorteio: {{ quota.raffle_id.title }}</h3>
                        <!-- Acessando title -->
                        <p><strong>Contato:</strong> {{ quota.contact }}</p>
                        <p><strong>Quantidade:</strong> {{ quota.quantity }}</p>
                        <p><strong>Status:</strong> {{ quota.status }}</p>
                        <p><strong>Criado em:</strong> {{ quota.createdAt | date: 'dd/MM/yyyy HH:mm' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Exibe uma mensagem de erro se nenhuma cota for encontrada -->
    <div *ngIf="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
    </div>
</section>

<app-footer></app-footer>