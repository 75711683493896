<!-- Header -->
<app-header></app-header>

<!-- Page Title -->
<div class="page-title-overlap bg-accent pt-4"></div>

<section class="container pb-md-4">
    <!-- Produto -->
    <div class="bg-light shadow-lg rounded-3 px-4 py-lg-4 py-3 mb-5">
        <div class="py-lg-3 py-2 px-lg-3">
            <div class="row gy-4">

                <!-- Exibe uma mensagem de sucesso se o pagamento for aprovado -->
                <div *ngIf="paymentApproved">
                    <h3>Pagamento Aprovado!</h3>
                    <p>Seu pagamento foi confirmado com sucesso. Obrigado por sua compra!</p>
                </div>

                <!-- QR Code PIX e detalhes do pagamento são exibidos apenas se o pagamento NÃO foi aprovado -->
                <ng-container *ngIf="!paymentApproved">
                    <div class="col-lg-6">
                        <h3>Como pagar com Pix:</h3>
                        <ol class="mb-4 pb-md-2 fs-sm">
                            <li>Acesse o app ou site do seu banco</li>
                            <li>Busque a opção de pagar com Pix</li>
                            <li>Leia o QR code ou código Pix</li>
                            <li>Pronto! Você verá a confirmação do pagamento</li>
                        </ol>
                        <p class="fw-bold">Valor a pagar: R$ {{amount}}</p>
                        <p>Escaneie o QR code:</p>
                        <div class="position-relative rounded-3 overflow-hidden mb-lg-4 mb-2">
                            <img [src]="qrCodeUrl" alt="QR Code PIX" class="img-fluid">
                        </div>
                        <p class="text-muted">Código válido por 30 minutos</p>
                    </div>

                    <!-- Detalhes do Pagamento -->
                    <div class="col-lg-6">
                        <div class="ps-xl-5 ps-lg-3">
                            <!-- QR Code Copia e Cola -->
                            <div class="mb-4 pb-md-2">
                                <h3 class="h6 mb-2 fs-sm text-muted">Se preferir, você pode pagar copiando e colando o
                                    seguinte código:</h3>
                                <pre class="bg-light p-3"
                                    style="white-space: pre-wrap; word-wrap: break-word;">{{pixCode}}</pre>
                            </div>

                            <!-- Botão de copiar código -->
                            <div class="col">
                                <button class="btn btn-lg btn-accent d-block w-100" (click)="copyToClipboard()">Copiar
                                    código</button>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</section>

<!-- Formato de fundo -->
<div class="pt-4 bg-secondary"></div>

<app-footer></app-footer>